import _decode from "./decode";
import _encode from "./encode";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decodeXMLStrict = exports.decodeHTML5Strict = exports.decodeHTML4Strict = exports.decodeHTML5 = exports.decodeHTML4 = exports.decodeHTMLStrict = exports.decodeHTML = exports.decodeXML = exports.encodeHTML5 = exports.encodeHTML4 = exports.escapeUTF8 = exports.escape = exports.encodeNonAsciiHTML = exports.encodeHTML = exports.encodeXML = exports.encode = exports.decodeStrict = exports.decode = exports.EncodingMode = exports.DecodingMode = exports.EntityLevel = void 0;
var decode_1 = _decode;
var encode_1 = _encode;
/** The level of entities to support. */

var EntityLevel;

(function (EntityLevel) {
  /** Support only XML entities. */
  EntityLevel[EntityLevel["XML"] = 0] = "XML";
  /** Support HTML entities, which are a superset of XML entities. */

  EntityLevel[EntityLevel["HTML"] = 1] = "HTML";
})(EntityLevel = exports.EntityLevel || (exports.EntityLevel = {}));
/** Determines whether some entities are allowed to be written without a trailing `;`. */


var DecodingMode;

(function (DecodingMode) {
  /** Support legacy HTML entities. */
  DecodingMode[DecodingMode["Legacy"] = 0] = "Legacy";
  /** Do not support legacy HTML entities. */

  DecodingMode[DecodingMode["Strict"] = 1] = "Strict";
})(DecodingMode = exports.DecodingMode || (exports.DecodingMode = {}));

var EncodingMode;

(function (EncodingMode) {
  /**
   * The output is UTF-8 encoded. Only characters that need escaping within
   * HTML will be escaped.
   */
  EncodingMode[EncodingMode["UTF8"] = 0] = "UTF8";
  /**
   * The output consists only of ASCII characters. Characters that need
   * escaping within HTML, and characters that aren't ASCII characters will
   * be escaped.
   */

  EncodingMode[EncodingMode["ASCII"] = 1] = "ASCII";
  /**
   * Encode all characters that have an equivalent entity, as well as all
   * characters that are not ASCII characters.
   */

  EncodingMode[EncodingMode["Extensive"] = 2] = "Extensive";
})(EncodingMode = exports.EncodingMode || (exports.EncodingMode = {}));
/**
 * Decodes a string with entities.
 *
 * @param data String to decode.
 * @param options Decoding options.
 */


function decode(data, options) {
  if (options === void 0) {
    options = EntityLevel.XML;
  }

  var opts = typeof options === "number" ? {
    level: options
  } : options;

  if (opts.level === EntityLevel.HTML) {
    if (opts.mode === DecodingMode.Strict) {
      return decode_1.decodeHTMLStrict(data);
    }

    return decode_1.decodeHTML(data);
  }

  return decode_1.decodeXML(data);
}

exports.decode = decode;
/**
 * Decodes a string with entities. Does not allow missing trailing semicolons for entities.
 *
 * @param data String to decode.
 * @param options Decoding options.
 * @deprecated Use `decode` with the `mode` set to `Strict`.
 */

function decodeStrict(data, options) {
  if (options === void 0) {
    options = EntityLevel.XML;
  }

  var opts = typeof options === "number" ? {
    level: options
  } : options;

  if (opts.level === EntityLevel.HTML) {
    if (opts.mode === DecodingMode.Legacy) {
      return decode_1.decodeHTML(data);
    }

    return decode_1.decodeHTMLStrict(data);
  }

  return decode_1.decodeXML(data);
}

exports.decodeStrict = decodeStrict;
/**
 * Encodes a string with entities.
 *
 * @param data String to encode.
 * @param options Encoding options.
 */

function encode(data, options) {
  if (options === void 0) {
    options = EntityLevel.XML;
  }

  var opts = typeof options === "number" ? {
    level: options
  } : options; // Mode `UTF8` just escapes XML entities

  if (opts.mode === EncodingMode.UTF8) return encode_1.escapeUTF8(data);

  if (opts.level === EntityLevel.HTML) {
    if (opts.mode === EncodingMode.ASCII) {
      return encode_1.encodeNonAsciiHTML(data);
    }

    return encode_1.encodeHTML(data);
  } // ASCII and Extensive are equivalent


  return encode_1.encodeXML(data);
}

exports.encode = encode;
var encode_2 = _encode;
Object.defineProperty(exports, "encodeXML", {
  enumerable: true,
  get: function () {
    return encode_2.encodeXML;
  }
});
Object.defineProperty(exports, "encodeHTML", {
  enumerable: true,
  get: function () {
    return encode_2.encodeHTML;
  }
});
Object.defineProperty(exports, "encodeNonAsciiHTML", {
  enumerable: true,
  get: function () {
    return encode_2.encodeNonAsciiHTML;
  }
});
Object.defineProperty(exports, "escape", {
  enumerable: true,
  get: function () {
    return encode_2.escape;
  }
});
Object.defineProperty(exports, "escapeUTF8", {
  enumerable: true,
  get: function () {
    return encode_2.escapeUTF8;
  }
}); // Legacy aliases (deprecated)

Object.defineProperty(exports, "encodeHTML4", {
  enumerable: true,
  get: function () {
    return encode_2.encodeHTML;
  }
});
Object.defineProperty(exports, "encodeHTML5", {
  enumerable: true,
  get: function () {
    return encode_2.encodeHTML;
  }
});
var decode_2 = _decode;
Object.defineProperty(exports, "decodeXML", {
  enumerable: true,
  get: function () {
    return decode_2.decodeXML;
  }
});
Object.defineProperty(exports, "decodeHTML", {
  enumerable: true,
  get: function () {
    return decode_2.decodeHTML;
  }
});
Object.defineProperty(exports, "decodeHTMLStrict", {
  enumerable: true,
  get: function () {
    return decode_2.decodeHTMLStrict;
  }
}); // Legacy aliases (deprecated)

Object.defineProperty(exports, "decodeHTML4", {
  enumerable: true,
  get: function () {
    return decode_2.decodeHTML;
  }
});
Object.defineProperty(exports, "decodeHTML5", {
  enumerable: true,
  get: function () {
    return decode_2.decodeHTML;
  }
});
Object.defineProperty(exports, "decodeHTML4Strict", {
  enumerable: true,
  get: function () {
    return decode_2.decodeHTMLStrict;
  }
});
Object.defineProperty(exports, "decodeHTML5Strict", {
  enumerable: true,
  get: function () {
    return decode_2.decodeHTMLStrict;
  }
});
Object.defineProperty(exports, "decodeXMLStrict", {
  enumerable: true,
  get: function () {
    return decode_2.decodeXML;
  }
});
export default exports;
export const __esModule = exports.__esModule,
      decodeXMLStrict = exports.decodeXMLStrict,
      decodeHTML5Strict = exports.decodeHTML5Strict,
      decodeHTML4Strict = exports.decodeHTML4Strict,
      decodeHTML5 = exports.decodeHTML5,
      decodeHTML4 = exports.decodeHTML4,
      decodeHTMLStrict = exports.decodeHTMLStrict,
      decodeHTML = exports.decodeHTML,
      decodeXML = exports.decodeXML,
      encodeHTML5 = exports.encodeHTML5,
      encodeHTML4 = exports.encodeHTML4,
      escapeUTF8 = exports.escapeUTF8,
      encodeNonAsciiHTML = exports.encodeNonAsciiHTML,
      encodeHTML = exports.encodeHTML,
      encodeXML = exports.encodeXML;
const _escape = exports.escape,
      _encode2 = exports.encode,
      _decodeStrict = exports.decodeStrict,
      _decode2 = exports.decode,
      _EncodingMode = exports.EncodingMode,
      _DecodingMode = exports.DecodingMode,
      _EntityLevel = exports.EntityLevel;
export { _escape as escape, _encode2 as encode, _decodeStrict as decodeStrict, _decode2 as decode, _EncodingMode as EncodingMode, _DecodingMode as DecodingMode, _EntityLevel as EntityLevel };